// TODO: move all the constants into a constants file
import { meanWorldLifeExpectancy, meanWorldAge } from '../constants/statistics';

function predictDeathDate(age, gender, country) {
    // TODO: add logic here

    const now = new Date()

    return new Date(now.getFullYear() + 50, now.getMonth(), now.getDate());
};

export default predictDeathDate;