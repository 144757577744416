import './App.css';
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PersonaliseOptions from './components/PersonaliseOptions';
import predictDeathDate from './helpers/calculateLifeExpectancy';

function App() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const [gender, setGender] = useState(queryParams.get("gender") || "");
  const [age, setAge] = useState(queryParams.get("age") || "");
  const [country, setCountry] = useState(queryParams.get("country") || "");

  const expectedDeathDate = useMemo(() => predictDeathDate(age, gender, country), [age, gender, country]);

  // Countdown starting value at 72.6 years minus 31 years
  const [countdown, setCountdown] = useState({ days: "...", hours: "...", minutes: "...", seconds: "..." });
  
  // Countdown timer which updates every second
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = expectedDeathDate - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expectedDeathDate]);

  return (
    <div className="Container">
      <div id="landing-section">
        <h1>
          ☠️ You have an estimated
        </h1>

        <div>
          <div>{countdown.days} days</div>
          <div>{countdown.hours} hours</div>
          <div>{countdown.minutes} minutes</div>
          <div>{countdown.seconds} seconds</div>
        </div>
        <h1>
          to live
        </h1>
        <p style={{ marginTop: '30px' }}>
          <i>
            <a href="#personalise-section" style={{ textDecoration: 'none', color: '#5F8DEf' }}>
              Personalise my score
            </a>
          </i>
        </p>
      </div>

      <div id="personalise-section">
        <h2>Personalise your score</h2>
        <PersonaliseOptions gender={gender} setGender={setGender} 
                            age={age} setAge={setAge} 
                            country={country} setCountry={setCountry}
                            location={location} queryParams={queryParams} /> 
        {/* // TODO: add links to official sources on life expectancies */}   
        {/* TODO: add a copy link, to enable sharing */}
      </div>
    </div>
  );
}

export default App;
