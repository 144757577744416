import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";


function PersonaliseOptions({ 
    gender, setGender, age, setAge, country,
    setCountry, location, queryParams 
  }) {

  const history = useHistory();

  const updateURL = (variable, value) => {
    queryParams.set(variable, value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  }  

    const handleGenderChange = (event) => {
      setGender(event.target.value);
      updateURL("gender", event.target.value);
    };
  
    const handleAgeChange = (event) => {
      setAge(event.target.value);
      updateURL("age", event.target.value);
    };
  
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
      updateURL("country", event.target.value);
    };

    return (
      <div>
        <h4>I am...</h4>
        <label>Gender:</label>
        <select value={gender} onChange={handleGenderChange}>
          <option value="">Select a gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
  
        <label>Age:</label>
        <select value={age} onChange={handleAgeChange}>
          <option value="">Select an age</option>
          <option value="18-24">18-24</option>
          <option value="25-34">25-34</option>
          <option value="35-44">35-44</option>
          <option value="45-54">45-54</option>
          <option value="55+">55+</option>
        </select>
  
        <label>Country:</label>
        <select value={country} onChange={handleCountryChange}>
          <option value="">Select a country</option>
          <option value="usa">USA</option>
          <option value="canada">Canada</option>
          <option value="uk">UK</option>
          <option value="australia">Australia</option>
          <option value="germany">Germany</option>
          <option value="france">France</option>
        </select>
      </div>
    );
  }
  
  export default PersonaliseOptions;